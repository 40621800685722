.App {
  font-family: sans-serif;
  text-align: center;
}

td span {
  width: 20px;
  height: 20px;
  display: inline-block;
}

td {
  padding: 0;
}

td.name {
  overflow-x: visible;
  max-width: 20px;
  margin-left: 10px;
}

td.name span {
  padding-left: 5px;
  font-size: 12px;
  line-height: 20px;
  width: 100px;
  text-align: left;
}

td a {
  color: black;
}

.color-75 {
  background-color: lightgreen;
}

.color-50 {
  background-color: yellow;
}

.color-25 {
  background-color: orange;
}

.color-0 {
  background-color: red;
}

.color-none {
  background-color: lightgrey;
}

.no-confusion {
  color: grey;
  font-size: 10px;
  line-height: 20px;
}

td.identity {
  font-weight: bold;
  font-size: 12px;
  line-height: 20px;
  /* border-bottom: 2px solid black;
  border-left: 2px solid black; */
}

table.matrix {
  border-spacing: 0px;
}

.bp3-tooltip .bp3-popover-content .smallTooltip {
  font-size: 80%;
}

.smallTooltip div label {
  width: 130px;
  margin: 0px;
}
